import React from "react";
import { Link, useNavigate } from "react-router-dom";

function NavBar({ searchText, setSearchText, }) {
   const updateText = (e)=>{
    // console.log(e.target.value)
    setSearchText(e.target.value)
   }
   const navigate = useNavigate()
   const historyHandler = (e)=>{
    navigate('/search')
   }
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={"/"}>
            Filmpire
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="gotosomewherenavbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to={"/"}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/about"}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/trending-movies"}>
                  Trending
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/nowplaying-movies"}>
                  NowPlaying
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="gotosomewhere"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="gotosomewhere">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="gotosomewhere">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="gotosomewhere">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  href="gotosomewhere"
                  className="nav-link disabled"
                  aria-disabled="true"
                >
                  Disabled
                </a>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <input
                className="form-control "
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={updateText}
                onKeyUp={historyHandler}
                value={searchText}
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
