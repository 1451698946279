import React from "react";
import MovieDetails from "./MovieDetails";
import TextTruncate from 'react-text-truncate';
import { Link } from "react-router-dom";

const MovieCard = ({movie}) => {
    const posterPath = `https://image.tmdb.org/t/p/w500${movie.poster_path}`
    
   
  return (
    
    <div className="col-md-3 my-5" style={{display:'inline-block'}}>
      <div className="card">
        <img src={posterPath} className="card-img-top" alt={movie.title} />
        <div className="card-body">
          <h5 className="card-title">{movie.title}</h5>
          <p className="card-text">
          <TextTruncate
    line={4}
    element="p"
    truncateText="…"
    text={movie.overview}
    textTruncateChild={<></>}
   
/>
          </p>
          <Link to={`/moviedetails/${movie.id}`}  className="btn btn-primary">
            
           Movie Details
          </Link>
        </div>
      </div>
    </div>
  
  );
};

export default MovieCard;
