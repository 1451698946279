import React from 'react'
import Hero from './Hero';

const About = () => {
    return (  
        <>
        <Hero title="About Us"/>
        <div className="container">
            <div className='row'>
                <div className='col-md-8 offset-md-2 my-5'>
                    <p className='lead'>
                    Eu magna nisi ut duis nisi dolor veniam ipsum nisi nostrud veniam. Duis velit duis adipisicing deserunt nostrud aliqua quis cillum ad irure. Cupidatat anim pariatur ullamco eu in amet.

Consectetur nisi mollit nostrud incididunt occaecat aliqua eiusmod aliquip nulla do. Ad adipisicing irure nulla aliqua eu nostrud. Incididunt do aute ullamco irure laborum do dolore do quis cillum est. Id incididunt cillum excepteur labore incididunt commodo voluptate officia labore. Officia amet consectetur ex sint aliqua ea nostrud cupidatat. Voluptate excepteur veniam velit non non labore voluptate eiusmod.

In pariatur quis dolore ullamco consectetur consectetur. Officia consectetur proident qui ipsum sunt in culpa ea sint exercitation mollit ut anim. Ea Lorem ullamco in enim commodo elit sunt sunt laborum nulla aute non. Dolor duis officia do dolor sunt veniam cupidatat tempor Lorem.
                    </p>
                </div>
            </div>
        </div>
        </>

    );
}
 
export default About;