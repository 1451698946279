import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MovieDetails = ({}) => {
  const { id } = useParams();
  const movieDetailUrl = `https://api.themoviedb.org/3/movie/${id}?api_key=8f666adc99ee1f6168974514ff6d951a`;
  var [movieDetails, setMovieDetails] = useState({});
  const posterPath = `https://image.tmdb.org/t/p/w500${movieDetails.poster_path}`;
  const backDrop = `https://image.tmdb.org/t/p/original${movieDetails.backdrop_path}`;
  useEffect(() => {
    fetch(movieDetailUrl)
      .then((response) => response.json())
      .then((data) => {
        movieDetails = data;
        setMovieDetails(movieDetails);
      });
  });
  return (
    
      <div className="container-fluid mask bg-image" style={{
         backgroundImage: `linear-gradient(rgb(224, 224,224,0.123), rgb(0,0,0.5)),url(${backDrop})`,
         height: "80vh",
         objectFit: "cover",
          backgroundPosition: "left calc((50vw - 80px) - 300px) top",
         backgroundSize: "cover",
         backgroundRepeat: "no-repeat",
        backgroundColor: " rgba(0, 0, 0, 0.8)", height: "80vh" }}>
       <div className="row">
        <div className="col-md-3">
          <div className="poster-wrapper mt-4 ms-2 justify-content-center align-items-center">
            <img
              src={posterPath}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
        </div>
        <div className="col-md-6 inline-block" style={{
             
            }}>
        
          
              <div class="d-flex h-10">
                <h1 className="text-light mt-5">{movieDetails.title}</h1>
               
              </div>
              <p className="lead text-light">{movieDetails.overview}</p>
            </div>
            
          </div>
         
        </div>
     
    
  );
};

export default MovieDetails;

{
  /* <div className="card mb-3" style={{ maxWidth: "540px" }}>
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={posterPath}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{movieDetails.title}</h5>
              <p className="card-text">{movieDetails.overview}</p>
              <p className="card-text">
                <small className="text-body-secondary">
                  Last updated 3 mins ago
                </small>
              </p>
            </div>
          </div>
        </div>
      </div> */
}
