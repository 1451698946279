import React, { useState, useEffect } from 'react'
import MovieCard from "./MovieCard";
import Hero from './Hero'

const NowPlaying =({movie, setMovie,}) => {
    var [result, setResult] = useState([])
    useEffect(() => { 
     
      const url = `https://api.themoviedb.org/3/movie/now_playing?api_key=8f666adc99ee1f6168974514ff6d951a`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          result=data.results
          setResult(result)
  
        });
    });
     const htmlResults = result.map((obj, i)=>{
      return <MovieCard movie={obj} key={i}/>
  
     })
     
    return (
      <>
        <Hero title="NowPlaying Movies"/>
      <div className="card-container">
        {htmlResults}
      </div>
      </>
  
    );







}


export default NowPlaying;
    
  