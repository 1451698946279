import React from 'react'
import Hero from './Hero';
import PopularMovie from './PopularMovie';


const Home = () => {
    return ( 
        <>
        <Hero title="Home"/>
         
        <PopularMovie />
        
        </>

     );
}
 
export default Home;

