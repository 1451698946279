import { useState } from 'react';
import './App.css';
import About from './Components/About';
import Home from './Components/Home';
import NavBar from './Components/NavBar';
import {
  Routes,
  Route
} from 'react-router-dom'
import Search from './Components/Search';
import MovieDetails from './Components/MovieDetails';
import Trending from './Components/Trending';
import NowPlaying from './Components/NowPlaying';


function App() {
const [searchText, setSearchText] = useState('')
const [searchResult, setSearchResult] = useState([])
const [movieId, setMovieId] = useState('')
  return (
    <>
   <NavBar searchText={searchText} setSearchText={setSearchText}/>
   <Routes>
    <Route path='/' element={<Home  />} />
    <Route path='/about' Component={About } />
    <Route path='/search' element={<Search keyword={searchText} />} />
    <Route path='/moviedetails/:id' Component={MovieDetails}    />
    <Route path='/trending-movies/' Component={Trending}  />
    <Route path='/nowplaying-movies/'Component={NowPlaying} />
     </Routes>
    
    </>
  );
}

export default App;
